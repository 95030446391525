import React from 'react';

export class PageColor extends React.Component {
  componentDidMount () {
    if (this.props.color) document.body.className = `background-${this.props.color}`;
    if (this.props.nav) document.querySelector('header').className = this.props.nav;
  }

  render = () => <React.Fragment/>
}
