import React from 'react';
import { PageColor } from '../components/page-color';
import { ContactForm } from '../components/contact-form';

export default class Contact extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  render = () => (
    <main className="contact">
      <PageColor color="tan" nav="white" />
      <div className="contact__image contact__image--top"><img src="/images/contact/top.jpg" /></div>
      <div className="contact__image contact__image--left"><img src="/images/contact/left.jpg" /></div>
      <div className="contact__content">
        <h1 className="contact__content__title">
          <div className="title-poppins">Hey you,</div>
          <div className="title-ivy">Let's get in touch</div>
        </h1>
        <div className="pt-16">
          <p className="pb-4">Some creepy knocks on our window, a holiday postcard or just this fill in form: however you’d like to tell us, we’re up for it! Weather you’re looking for some general advice or have a specific campaign in mind: give us a sign and we’re here to guide you through!</p>
          <p className="font-bold">Can’t wait? Neither can we! Call us on +32 498 14 54 29 or ping us on social!</p>

          <ContactForm />
        </div>
        <div className="contact__content__details">
          <div>
            Harensesteenweg 224<br/>
            1800 Vilvoorde, Belgium
          </div>
          <div>
            +32 498 14 54 29<br/>
            <a href="mailto:yeswecan@nativenation.eu">yeswecan@nativenation.eu</a>
          </div>
        </div>
        <div className="contact__content__social">
          <a href="https://www.facebook.com/nativenationeu" target="_blank"><img src="/icons/facebook.svg" /></a>
          <a href="https://www.instagram.com/nativenationeu/" target="_blank"><img src="/icons/instagram.svg" /></a>
          <a href="https://twitter.com/nativenationeu" target="_blank"><img src="/icons/twitter.svg" /></a>
          <a href="https://www.linkedin.com/company/native-nation/about/" target="_blank"><img src="/icons/linkedin.svg" /></a>
        </div>
      </div>
    </main>
  );
}
