import React from 'react';
import axios from 'axios';
import serialize from 'form-serialize';

export class ContactForm extends React.Component {
  constructor () {
    super();

    this.state = {
      sending: false,
      isValid: true,
      hasError: false,
      thankYou: false
    };
  }

  validate (data) {
    var isValid = true;
    if (!data.name) isValid = false;
    if (!data.email) isValid = false;
    if (!data.message) isValid = false;
    return isValid;
  }

  submit = (e) => {
    e.preventDefault();
    if (this.state.sending) return;

    const data = serialize(this.refs.form, { hash: true });
    const isValid = this.validate(data);

    this.setState({ isValid, hasError: false });

    if (isValid) {
      this.setState({ sending: true });
      axios.post('/.netlify/functions/contact-form', data).then(response => {
        if (response.status === 200) this.setState({ thankYou: true });
        else this.setState({ hasError: true });
        this.setState({ sending: false });
      });
    }
  }

  render () {
    if (this.state.thankYou) return this.renderThankYou();

    return (
      <form onSubmit={this.submit} ref="form" className="contact-form">
        <input type="text" name="name" placeholder="Your name" />
        <input type="text" name="email" placeholder="Your e-mail address"/>
        <textarea id="message" name="message" placeholder="Your message"></textarea>
        
        <input type="submit" value={this.state.sending ? 'Sending...' : 'Submit' } />

        { !this.state.isValid && <p className="error">All fields are required</p> }
        { this.state.hasError && <p className="error">Oops, something went wrong!</p> }
      </form>
    );
  }

  renderThankYou () {
    return <p>Thanks for your message! We're excited to hear from you and will get back to you soon.</p>
  }
}
